
export default {
  name: 'LogoComponent',
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
  },
}
