

import {useStoryblokApi, useStoryblokBridge} from "@storyblok/nuxt-2";
import LandingpageToolbar from "~/components/layout/LandingpageToolbar.vue";
import {generateHead} from "~/helpers/headGenerator";

export default {
  name: 'IndexPage',
  components: {
    LandingpageToolbar
  },
  auth: 'guest',
  layout: 'landingpage',
  async asyncData({route, redirect, app}) {
    try {
      const storyblokApi = useStoryblokApi()
      const version =
          route.query?._storyblok !== undefined ? 'draft' : 'published'
      const locale = app.i18n.locale
      const {data} = await storyblokApi.get(
          `cdn/stories/${route.params.slug || 'index'}?fallback_lang=de&language=${locale}`,
          {
            version,
          }
      )
      return {story: data.story}
    } catch (err) {
      console.warn(err)
      redirect(app.localePath('/'))
    }
  },
  data: () => ({
    story: null,
  }),
  head() {
    return generateHead(
      this.story.content.metaTitle ?? this.$t('meta.index.title'),
      this.story.content.metaDescription ?? this.$t('meta.index.description'),
    )
  },
  computed: {
    loginUrl() {
      return this.story?.content?.loginUrl
    },
    currentLocale() {
      return this.$i18n.locale
    }
  },
  watch: {
    async currentLocale() {
      const storyblokApi = useStoryblokApi()
      const version =
          this.$route.query?._storyblok !== undefined ? 'draft' : 'published'
      const locale = this.$i18n.locale
      const {data} = await storyblokApi.get(
          `cdn/stories/${this.$route.params.slug || 'index'}?fallback_lang=de&language=${locale}`,
          {
            version,
          }
      )
      this.story = data.story
    }
  },
  mounted() {
    if (
        this.story &&
        this.story.id &&
        this.$route.query?._storyblok !== undefined
    ) {
      useStoryblokBridge(this.story.id, (evStory) => (this.story = evStory))
    }
  },
}
