export function generateHead(title, description, image) {
  const head = { meta: [] }

  if (title) {
    head.title = title
    head.meta.push({ hid: 'og:title', name: 'og:title', content: title })
  }
  if (description) {
    if (description.length > 320) {
      description = description.substring(0, 317) + '...'
    }
    head.meta.push({
      hid: 'description',
      name: 'description',
      content: description,
    })
    head.meta.push({
      hid: 'og:description',
      name: 'og:description',
      content: description,
    })
  }
  if (image) {
    head.meta.push({
      hid: 'og:image',
      name: 'og:image',
      content: image,
    })
  }
  return head
}
