
import { mdiMenuOpen } from '@mdi/js'
import Logo from '@/components/layout/Logo'
import LanguageSelect from "~/components/base/LanguageSelect.vue";

export default {
  name: 'ToolbarComponent',
  components: {LanguageSelect, Logo },
  props: {
    loginUrl: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    mdiMenuOpen,
  }),
  computed: {
  loginHref() {
      return this.localePath('/redirect')

    },
    signupHref() {
      return this.localePath({ path: '/redirect', query: { screen_hint: 'signup' }})
    },
    initials() {
      const user = this.$auth.user
      if (!user) return ''
      if (user.given_name || user.family_name) {
        return `${user.given_name?.charAt(0)}${user.family_name?.charAt(0)}`
      }
      if (user.nickname) {
        const split = user.nickname.split('.')
        return `${split[0].charAt(0)}${split[1].charAt(0)}`
      }
      return user.name?.charAt(0)
    },
  },
}
